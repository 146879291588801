<template>
  <form class="forget-pw-form" @submit.prevent="onSubmit">
    <div class="form-group">
      <input
        type="password"
        class="form-control password-input"
        id="password-input"
        aria-describedby="passwordInput"
        placeholder="Enter your new password"
        autocomplete="off"
        v-model="form.password"
        v-validate="'required'"
        :class="{'is-danger': errors.has('password')}"
        name="password"
        ref="password"
      >
      <span v-show="errors.has('password')" class="help is-danger">{{ errors.first('password') }}</span>
    </div>
    <div class="form-group">
      <input
        type="password"
        class="form-control password-input"
        id="resetPassword-input"
        aria-describedby="passwordInput"
        placeholder="Re-enter your new password"
        autocomplete="off"
        v-model="form.resetPassword"
        v-validate="'required|confirmed:password'"
        :class="{'is-danger': errors.has('password_confirmation')}"
         name="password_confirmation"
        data-vv-as="password" 
      >
      <span v-show="errors.has('password_confirmation')" class="help is-danger">{{ errors.first('password_confirmation') }}</span>
    </div>
    <button type="submit" class="btn btn-primary">Reset Password</button>
  </form>
</template>

<script>
import axios from "axios";

export default {
  props: {
    userId: String
  },
  data: function() {
    return {
      form: {
        password: null,
        resetPassword: null
      }
    };
  },
  methods: {
    async submitRequest() {
      try {
        let res = await axios({
          url: `http://testdeployd.aezypay.com/reset-password`,
          method: 'put',
          data: {
            id: this.userId,
            password: this.form.password
          }
        })
        this.form = {
          password: null, 
          resetPassword: null
        }
        this.$router.push('/login')

      } catch (error) {
        console.error('error', error)
      }
    },
    onSubmit(){
       this.$validator.validate().then(valid => {
        if (valid) {
          console.log('valid', valid)
          this.submitRequest()
          return 
        }
        console.log("errors");
      });
    }
  }
};
</script>

<style lang="scss">
$line-color: #e6e7e8;
$placeholder-color: #585956;

.forget-pw-form {
  // text-align: left;
  padding: 30px 2vw;
  .form-group {
    // color: $text-color;
    // margin-bottom: 50px;
    height: 8vh;
    text-align: left;

    label {
      outline: none;
    }

    input[type="password"] {
      border: none;
      outline: none;
      border-bottom: 1px solid $line-color;
      border-radius: 0;
      box-shadow: none;

      vertical-align: middle;
    }

    ::-webkit-input-placeholder {
      color: $placeholder-color;
      font-size: 13px;
    }
    ::-moz-placeholder {
      color: $placeholder-color;
      font-size: 13px;
    }
    :-ms-input-placeholder {
      color: $placeholder-color;
      font-size: 13px;
    }
    ::placeholder {
      color: $placeholder-color;
      font-size: 13px;
    }

    .help.is-danger{
      font-size: 12px;
      text-align: center;
      color: red;
    }
  }

  button[type="submit"] {
    background-color: #3f599a;
    color: rgba(#ffffff, 0.8);
    border: none;
    outline: none;
    border-radius: 15px;
    padding: 5px 50px;
    // margin: 7px 0;
    cursor: pointer;

    &:focus {
      box-shadow: none;
    }
  }
}
</style>
