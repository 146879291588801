<template>
  <div class="reset-pw">
    <div class="card-1">
      <div class="circle-1"></div>
      <div class="circle-2"></div>
      <img src="@/assets/img/login/admin-logo.png" alt="logo" class="logo">
      <div class="reset-form">
        <h1>Reset Password</h1>
        <ResetForm v-if="validLink" :userId="userId"/>
        <p v-else>Sorry invalid link</p>
        <small class="copyright">Copyright aezpay pte. ltd. 2019</small>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ResetForm from "@/components/login/ResetForm.vue";

export default {
  name: "reset-password",
  head: {
    title: { inner: "Reset Password" }
  },
  data: function() {
    return {
      // validLink: false,
      userId: null
    };
  },
  components: {
    ResetForm
  },
  computed: {
    validLink() {
      return this.userId ? true: false;
    }
  },
  mounted() {
    this.getUserId()
    console.log(this.$router.params.id)
  },
  methods: {
    async getUserId() {
      try {
        let res = await axios({
          url: "/reset-pw",
          method: "get",
          params: {
            // id: this.$router.params.id
          }
        });
        if (res.data.length > 0) {
          this.userId = res.data[0].userId
        } else {
          console.log('error', res)
        }
        
      } catch (error) {
        console.log("error", error);
      }
    }
  }
};
</script>

<style lang="scss">
@import "../assets/css/project";

.reset-pw {
  height: 100vh;
  background: url("../assets/img/login/bg-1.png") no-repeat center;
  background-size: cover;

  margin-left: (-$grid-gutter-width / 2);
  margin-right: (-$grid-gutter-width / 2);

  display: flex;
  justify-content: center;
  align-items: center;

  // card design
  .card-1 {
    width: 80vw;
    height: 80vh;
    background: url("../assets/img/login/img-1.png") no-repeat left,
      url("../assets/img/login/bg-2.png") no-repeat center;
    background-size: 45%, cover;

    border-radius: 30px;
    box-shadow: 10px 10px 28px 0px rgba(102, 102, 102, 0.25);
  }

  // positioning within card
  .card-1 {
    position: relative;
    .circle-1 {
      height: 2vw;
      width: 2vw;
      border-radius: 50%;
      background-color: #6479ae;

      position: absolute;
      right: 10%;
      top: 8%;
    }
    .circle-2 {
      height: 5vw;
      width: 5vw;
      border-radius: 50%;
      background-color: #6479ae;

      position: absolute;
      right: 3%;
      top: 15%;
    }
    .logo {
      position: absolute;
      // width: 20%;
      width: 26vh;
      // right: 15.8%;
      right: 13.8vw;
      top: 14.5%;
    }
    .reset-form {
      position: absolute;
      right: 11%;
      top: 30%;

      h1 {
        // margin-top: 35%;
        margin-top: 12vh;
        position: relative;
        z-index: 1;
        &:before,
        &:after {
          position: absolute;
          top: 45%;
          overflow: hidden;
        }
      }
    }
  }

  // reset-form
  .card-1 {
    .reset-form {
      height: 60%;
      width: 30%;
      border-radius: 15px;
      box-shadow: 0px 0px 28px 0px rgba(102, 102, 102, 0.25);

      h1 {
        font-size: 16px;
        color: #668ed6;
        overflow: hidden;
        text-transform: uppercase;
        text-align: center;

        &:before,
        &:after {
          width: 20%;
          height: 1.5px;
          content: "\a0";
          background-color: #668ed6;
        }

        &:before {
          margin-left: -24%;
          text-align: right;
        }
        &:after {
          margin-left: 5%;
        }
      }
      .small-text {
        padding: 0 2vw;
        font-size: 12px;
      }

      .copyright {
        font-size: 10px;
        color: #bbbdbf;
      }
    }
  }
}
</style>
